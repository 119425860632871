<template>
  <div class="iss-main schedule">
    <a-row>
      <a-col :span="12" class="titleLeft">
        <div class="toDay-title">人脉圈分类</div>
        <div class="toDay-hello">分类管理您的人脉圈，高效维护人脉资源。</div>
      </a-col>
      <a-col :span="12" class="titleRight">
        <div class="titleRight-nav">
          <template v-if="isDrag">
            <a-button @click="handleAddCircleList">新增人脉圈</a-button>
            <div class="operate">
              <a-popover placement="bottom">
                <template #content>
                  <div
                    class="marginBottom5"
                    style="cursor: pointer"
                    @click="handleEditList"
                  >
                    <component
                      :is="icons['EditOutlined']"
                      class="activeColor marginRight5"
                    ></component>
                    管理分类
                  </div>
                  <div
                    class="marginTop5"
                    style="cursor: pointer"
                    @click="handleAddList"
                  >
                    <component
                      :is="icons['PlusOutlined']"
                      class="activeColor marginRight5"
                    ></component>
                    新增分类
                  </div>
                </template>
                <component :is="icons['EllipsisOutlined']"></component>
              </a-popover>
            </div>
          </template>
          <template v-else>
            <a-button type="primary" @click="onSubmit">完成</a-button>
          </template>
        </div>
      </a-col>
    </a-row>
    <div class="gutter-example guidelines">
      <div class="editTag">
        <a-tag color="#404040" v-if="!isDrag">可拖拽分类进行排序</a-tag>
      </div>
      <draggable
        :list="list"
        ghost-class="ghost"
        :disabled="isDrag"
        chosen-class="chosenClass"
        animation="300"
        @start="onStart"
        @end="onEnd(list)"
        class="draggable"
      >
        <template #item="{ element }">
          <div class="draggItem" @click="goToDetail(element)">
            <div class="icon">
              <component :is="icons[`${element.icon}` || 'UserOutlined']"></component>
            </div>
            <div class="name">
              {{ element.connectionsName }} <span>({{ element.size }})</span>
            </div>
            <div class="outline">
              <template v-if="isDrag">
                <a-tooltip>
                  <template #title>详情</template>
                  <component
                    :is="icons['RightOutlined']"
                    @click="goToDetail(element)"
                  ></component>
                </a-tooltip>
              </template>
              <template v-else>
                <a-tooltip>
                  <template #title>编辑</template>
                  <component
                    :is="icons['EditOutlined']"
                    style="margin-bottom: 10px"
                    @click="handleEditItem(element)"
                  ></component>
                </a-tooltip>
                <a-tooltip>
                  <template #title>删除</template>
                  <component
                    :is="icons['DeleteOutlined']"
                    @click="handleDelItem(element.id, element.size)"
                  ></component>
                </a-tooltip>
              </template>
            </div>

            <component
              v-if="!isDrag"
              :is="icons['DragOutlined']"
              class="dragOut"
            ></component>
          </div>
        </template>
      </draggable>
    </div>
  </div>
  <a-modal
    v-model:visible="visible"
    :title="form.id ? '编辑人脉圈分类' : '新增人脉圈分类'"
    centered
    @cancel="handleCancel"
    class="contactsCircle modal"
  >
    <a-form :model="form" :label-col="labelCol" :rules="rules" ref="formRef">
      <a-form-item label="分类名称" name="connectionsName">
        <a-input
          placeholder="请输入人脉圈分类名称"
          v-model:value="form.connectionsName"
        >
          <template #addonBefore>
            <a-button @click="visibleICon = true">
              <component :is="icons[form.icon || 'UserOutlined']" />
            </a-button>
          </template>
        </a-input>
      </a-form-item>
    </a-form>

    <template v-slot:footer>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleOk" :loading="loading"
        >确认</a-button
      >
    </template>
  </a-modal>

  <a-modal
    v-model:visible="visibleT"
    :title="'新增人脉圈'"
    centered
    @ok="handleOkT"
    @cancel="handleCancelT"
    :destroyOnClose="true"
    class="contactsCircle modal"
  >
    <a-form :model="formT" :label-col="labelCol" :rules="rulesT" ref="formRefT">
      <a-form-item label="人脉圈名称" name="connectionsName">
        <a-input
          placeholder="请输入人脉圈名称"
          v-model:value="formT.connectionsName"
        >
          <template #addonBefore>
            <a-button @click="visibleIConT = true">
              <component :is="icons[formT.icon || 'FolderOpenOutlined']" />
            </a-button>
          </template>
        </a-input>
      </a-form-item>
      <a-form-item label="描述">
        <a-input
          placeholder="请输入人脉圈描述"
          v-model:value="formT.description"
        >
        </a-input>
      </a-form-item>
      <!--    todo   -->
      <a-form-item label="人脉圈分类" name="parentId">
        <a-select placeholder="请选择人脉圈分类" v-model:value="formT.parentId">
          <a-select-option
            v-for="item in circleList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.connectionsName }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <template v-slot:footer>
      <a-button @click="handleCancelT">取消</a-button>
      <a-button type="primary" @click="handleOkT" :loading="loadingT"
        >确认</a-button
      >
    </template>
  </a-modal>

  <icon-modal
    v-model:visible="visibleICon"
    :init-value="form.icon"
    @fnOk="value => (form.icon = value)"
  />

  <icon-modal
    v-model:visible="visibleIConT"
    :init-value="formT.icon"
    @fnOk="value => (formT.icon = value)"
  />
</template>

<script>
import {
  Row,
  Select,
  Col,
  Popover,
  Tooltip,
  Tag,
  Form,
  Modal,
  message,
} from 'ant-design-vue';
import { reactive, toRefs, createVNode, onMounted, ref } from 'vue';
import draggable from 'vuedraggable';
import * as icons from '@ant-design/icons-vue';
import IconModal from '@/components/iconModal';
import contactsCircleApi from '@/api/contactsCircle';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
  components: {
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    ASelectOption: Select.Option,
    ATag: Tag,
    AForm: Form,
    AFormItem: Form.Item,
    APopover: Popover,
    ATooltip: Tooltip,
    Icon: icons,
    draggable,
    IconModal,
    Modal,
  },
  setup() {
    const formRef = ref();
    const formRefT = ref();
    const store = useStore();
    const router = useRouter();
    const userId = store.state.account.user.id;
    const state = reactive({
      list: [
        //   { name: '工作', id: 0, value: 51, icon: 'GlobalOutlined' },
        //   { name: '兴趣', id: 1, value: 10, icon: 'HeartOutlined' },
        //   { name: '生活', id: 1, value: 7, icon: 'CoffeeOutlined' },
        //   { name: '其他', id: 1, value: 85, icon: 'AppstoreAddOutlined' },
      ],
      circleList: [],
      loading: false,
      loadingT: false,
      isDrag: true,
      visibleICon: false,
      visibleIConT: false,
      visible: false,
      visibleT: false,
    });
    const form = reactive({
      connectionsName: '',
      parentId: '0',
      icon: 'UserOutlined',
      userId: store.state.account.user.id,
      id: '',
    });
    const formT = reactive({
      connectionsName: '',
      description: '',
      parentId: null,
      userId: store.state.account.user.id,
      icon: 'FolderOpenOutlined',
    });
    //拖拽开始的事件
    const onStart = () => {
      console.log('开始拖拽', state.list);
    };
    // todo 拖拽结束的事件
    const onEnd = list => {
      // sortValue
      let ary = list;
      // console.log('结束拖拽', ary);

      let newArray = ary.map((item, index) => ({
        ...item,
        sortValue: index + 1,
      }));

      let parmas = {
        userId: store.state.account.user.id,
        connectionsIdAndSortDTOS: newArray,
      };
      contactsCircleApi.sortConnection('', parmas).then(res => {
        if (res) {
          console.log('ren', res);
          message.success('拖拽成功');
          // state.spinning = true;
          // 查询脉圈列表
          getDragList();
          getData();
        }
      });
    };
    const handleEditList = () => {
      state.isDrag = false;
    };
    const handleAddList = () => {
      // formRef.value.resetFields();
      state.visible = true;
    };
    const handleAddCircleList = () => {
      state.visibleT = true;
    };
    const onSubmit = () => {
      state.isDrag = true;
    };
    const handleDelItem = (id, value) => {
      if (value != 0) {
        Modal.confirm({
          content: '请先清空该分类下的人脉圈，再删除该分类。',
          icon: createVNode(icons['ExclamationCircleOutlined']),
          onOk() {},
          okText: '确认',
          cancelText: '取消',
          onCancel() {
            Modal.destroyAll();
          },
        });
      } else {
        //删除
        contactsCircleApi
          .deleteItem('', {
            userId: store.state.account.user.id,
            connectionsIds: [id],
          })
          .then(res => {
            if (res) {
              message.success('删除成功');
              getDragList(); //获取人脉分类列表
            }
          });
      }
    };
    const getDragList = () => {
      contactsCircleApi.getList('', userId).then(res => {
        if (res) {
          state.list = res;
        }
      });
    };
    const handleEditItem = data => {
      Object.assign(form, data);
      state.visible = true;
    };
    //新增/修改人脉圈子
    const handleOk = () => {
      formRef.value.validate().then(() => {
        state.loading = true;
        console.log('form', form);
        const obj = JSON.parse(JSON.stringify(form));
        let type = 'addCircle';
        form.id && (type = 'updateCircle');
        contactsCircleApi[type]('', {
          ...obj,
        }).then(res => {
          if (res) {
            message.success(form.id ? '编辑成功' : '添加成功');
            getDragList(); //获取人脉分类列表
            state.visible = false;
            formRef.value.resetFields();
          }
          state.loading = false;
        });
      });
    };
    //新增人脉圈
    const handleOkT = () => {
      formRefT.value.validate().then(() => {
        state.loadingT = true;
        const obj = JSON.parse(JSON.stringify(formT));
        contactsCircleApi
          .addCircle('', {
            ...obj,
          })
          .then(res => {
            if (res) {
              message.success('添加成功');
              state.visibleT = false;
              getDragList(); //获取人脉分类列表
              formRefT.value.resetFields();
              //   进入人人脉圈分类
              router.push(`/contactsCircle/${res.parentId}`);
            }
            state.loadingT = false;
          });
      });
    };
    const handleCancel = () => {
      formRef.value.resetFields();
      state.visible = false;
    };
    const handleCancelT = () => {
      formRefT.value.resetFields();
      state.visibleT = false;
    };

    const getData = () => {
      contactsCircleApi.getCircleTree('', userId).then(res => {
        if (res) {
          console.log(res);
          state.circleList = res;
        }
      });
    };

    onMounted(() => {
      getDragList(); //获取人脉分类列表
      getData();
      // contactsCircleApi.getCircleTree('', userId).then(res => {
      //   if (res) {
      //     console.log(res)
      //     state.circleList = res;
      //   }
      // });
    });

    return {
      formRef,
      formRefT,
      form,
      formT,
      rules: {
        connectionsName: [
          {
            required: true,
            message: '请输入人脉圈分类名称',
            trigger: 'blur',
          },
          {
            max: 12,
            message: '长度不能超过12字',
            trigger: 'blur',
          },
        ],
      },
      rulesT: {
        connectionsName: [
          {
            required: true,
            message: '请输入人脉圈名称',
            trigger: 'blur',
          },
          {
            max: 12,
            message: '长度不能超过12字',
            trigger: 'blur',
          },
        ],
        description: [
          {
            // required: true,
            message: '请输入人脉圈描述',
            trigger: 'blur',
          },
          {
            max: 50,
            message: '长度不能超过50字',
            trigger: 'blur',
          },
        ],
        parentId: [
          {
            required: true,
            message: '请选择人脉圈分类',
            trigger: 'blur',
          },
        ],
      },
      icons,
      onStart,
      onEnd,
      handleEditList,
      handleEditItem,
      handleAddList,
      handleAddCircleList,
      onSubmit,
      handleCancel,
      handleCancelT,
      handleDelItem,
      handleOk,
      handleOkT,
      goToDetail(element) {
        // console.log('element999');
        if (state.isDrag) {
          router.push(`/contactsCircle/${element.id}`);
        }
      },
      labelCol: {
        span: 24,
      },
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main {
  padding: 32px 54px;
  .titleLeft {
    .toDay-title {
      color: #333333;
      font-weight: 500;
      font-size: 24px;
    }
    .toDay-hello {
      margin-top: 12px;
      color: #666666;
      font-size: 12px;
    }
  }
  .titleRight {
    color: #ff8f1f;
    margin-top: 21px;
    text-align: right;
    font-size: 14px;
    .titleRight-nav {
      display: flex;
      justify-content: right;
      :deep(.ant-btn) {
        background: #ff7b00;
        border: 1px solid #ff7b00;
        color: #fff;
      }

      .operate {
        margin-left: 15px;
        width: 32px;
        height: 32px;
        line-height: 32px;
        border-radius: 4px;
        text-align: center;
        opacity: 1;
        background: #ffffff;
        box-sizing: border-box;
        border: 1px solid #eeeff5;
        color: #333;
        font-size: 20px;
      }
    }
  }
  .guidelines {
    text-align: center;
    margin-top: 22px;
    color: #999999;
    .editTag {
      text-align: left;
    }
    .draggable {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      max-height: calc(100vh - 258px);
      overflow-y: auto;
      .draggItem {
        width: 250px;
        height: 80px;
        border-radius: 8px;
        background: radial-gradient(
          177% 176% at 100% 77%,
          rgba(255, 239, 255, 0.9) 0%,
          rgba(255, 249, 246, 0.9) 28%,
          rgba(255, 235, 234, 0.9) 57%,
          rgba(225, 235, 255, 0.9) 99%
        );
        box-sizing: border-box;
        border: 1px solid #ffffff;
        box-shadow: 0px 0px 8px 0px rgba(114, 142, 250, 0.08);
        display: flex;
        align-items: center;
        padding: 0 25px;
        position: relative;
        cursor: pointer;
        margin: 5px 15px 15px 5px;
        .icon {
          font-size: 28px;
          color: #ff8f1f;
        }
        .name {
          flex: 1;
          text-align: left;
          margin-left: 15px;
          color: #ff8f1f;
          font-size: 15px;
          span {
            display: inline-block;
            margin-left: 8px;
          }
        }
        .outline {
          font-size: 16px;
          color: #ff8f1f;
          display: flex;
          flex-direction: column;
        }
        .dragOut {
          position: absolute;
          top: -7px;
          left: -7px;
          font-size: 20px;
          color: #ff8f1f;
        }
      }
    }
  }
}
:deep(.modal .ant-input-group-addon) {
  padding: 0;
}
</style>
